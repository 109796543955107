import { useState } from "react";
import { useCookies } from "react-cookie";
import { MdOutlineAttachMoney } from "react-icons/md";
import { FaRegFlag, FaRegCalendar } from "react-icons/fa";
import { FaFolderOpen } from "react-icons/fa6";
import { FcClock } from "react-icons/fc";
import { IoPersonSharp } from "react-icons/io5";

import classes from "./Investment.module.css";
import { formatAmount, formatDate } from "../../util/helpers";
import {
  approveInvestment,
  approveWithdraw,
  deleteInvestment,
} from "../../api/api";
import Spinner from "../UI/Spinner";
import AuthAlert from "../alerts/AuthAlert";

const Investment = (props) => {
  const [showSpinner, setShowSpinner] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [alertStatus, setAlertStatus] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const { jwt } = useCookies(["jwt"])[0];

  const approveInvestmentHandler = async () => {
    setShowSpinner(true);

    const res = await approveInvestment(props.id, jwt);

    if (res.status === "success") {
      setAlertMsg(res.message);
      setAlertStatus(true);
      setShowAlert(true);
      props.onReload((prevState) => !prevState);
    } else {
      setAlertMsg(res.message);
      setAlertStatus(false);
      setShowAlert(true);
    }
    setShowSpinner(false);
    setTimeout(() => {
      setShowAlert(false);
    }, 4000);
  };
  const approveWithdrawHandler = async () => {
    setShowSpinner(true);

    const res = await approveWithdraw(props.id, jwt);

    if (res.status === "success") {
      setAlertMsg(res.message);
      setAlertStatus(true);
      setShowAlert(true);
      props.onReload((prevState) => !prevState);
    } else {
      setAlertMsg(res.message);
      setAlertStatus(false);
      setShowAlert(true);
    }
    setShowSpinner(false);
    setTimeout(() => {
      setShowAlert(false);
    }, 4000);
  };
  const deleteInvestmentHandler = async () => {
    setShowSpinner(true);

    const res = await deleteInvestment(props.id, jwt);

    if (!res.status) {
      setAlertMsg("Investment deleted successfully");
      setAlertStatus(true);
      setShowAlert(true);
      props.onReload((prevState) => !prevState);
    } else {
      setAlertMsg(res.message);
      setAlertStatus(false);
      setShowAlert(true);
    }
    setShowSpinner(false);
    setTimeout(() => {
      setShowAlert(false);
    }, 4000);
  };

  return (
    <div className={classes.investment}>
      {showSpinner && <Spinner />}
      {showAlert && <AuthAlert message={alertMsg} status={alertStatus} />}
      <div className={classes.content}>
        <div className={classes.title}>
          <FaFolderOpen className={classes.icon} />
          <span>Portfolio</span>
        </div>
        <span className={classes.value}>{props.name.toUpperCase()}</span>
      </div>
      <div className={classes.content}>
        <div className={classes.title}>
          <IoPersonSharp className={classes.icon} />
          <span>Investor</span>
        </div>
        <span className={classes.value}>{props.investor}</span>
      </div>
      <div className={classes.content}>
        <div className={classes.title}>
          <MdOutlineAttachMoney className={classes.icon} />
          <span>Invested</span>
        </div>
        <span className={classes.value}>
          ${formatAmount(props.investedAmount)}
        </span>
      </div>
      <div className={classes.content}>
        <div className={classes.title}>
          <MdOutlineAttachMoney className={classes.icon} />
          <span>Total returns</span>
        </div>
        <span className={classes.value}>${formatAmount(props.returns)}</span>
      </div>
      <div className={classes.content}>
        <div className={classes.title}>
          <MdOutlineAttachMoney className={classes.icon} />
          <span>Weekly returns</span>
        </div>
        <span className={classes.value}>
          ${formatAmount(props.weeklyReturns)}
        </span>
      </div>
      <div className={classes.content}>
        <div className={classes.title}>
          <FcClock className={classes.icon} />
          <span>Contract length</span>
        </div>
        <span className={classes.value}>{props.contractLength} Months</span>
      </div>
      <div className={classes.content}>
        <div className={classes.title}>
          <FaRegCalendar className={classes.icon} />
          <span>Date Invested</span>
        </div>
        <span className={classes.value}>{formatDate(props.createdAt)}</span>
      </div>
      <div className={classes.content}>
        <div className={classes.title}>
          <FaRegCalendar className={classes.icon} />
          <span>Start date</span>
        </div>
        <span className={classes.value}>
          {formatDate(props.startDate) || "N/A"}
        </span>
      </div>
      <div className={classes.content}>
        <div className={classes.title}>
          <FaRegCalendar className={classes.icon} />
          <span>End date</span>
        </div>
        <span className={classes.value}>
          {formatDate(props.endDate) || "N/A"}
        </span>
      </div>
      <div className={classes.content}>
        <div className={classes.title}>
          <FaRegCalendar className={classes.icon} />
          <span>Last updated</span>
        </div>
        <span className={classes.value}>{formatDate(props.updatedAt)}</span>
      </div>
      <div className={classes.content}>
        <div className={classes.title}>
          <FaRegFlag className={classes.icon} />
          <span>Status</span>
        </div>
        <span className={classes.value}>{props.status}</span>
      </div>
      <div className={classes.content}>
        <div className={classes.title}>
          <FaRegFlag className={classes.icon} />
          <span>Withdraw Status</span>
        </div>
        <span className={classes.value}>
          {props.withdrawStatus?.toUpperCase()}
        </span>
      </div>
      <div className={classes.action}>
        {props.status === "created" && (
          <button onClick={approveInvestmentHandler}>Approve Investment</button>
        )}
        {props.withdrawStatus === "applied" && (
          <button onClick={approveWithdrawHandler}>Approve Withdraw</button>
        )}
        <button onClick={deleteInvestmentHandler}>Delete Investment</button>
      </div>
    </div>
  );
};

export default Investment;
