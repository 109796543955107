import { useState, useEffect } from "react";
import { useCookies } from "react-cookie";

import classes from "./Users.module.css";
import { getUsers } from "../../api/api";
import UserItem from "./UserItem";
import Loading from "../UI/Loading";

const Users = () => {
  const [users, setUsers] = useState([]);
  const { jwt } = useCookies(["jwt"])[0];
  const [isLoading, setIsLoading] = useState(true);
  const [reload, setReload] = useState(false);

  useEffect(() => {
    const fetchUsers = async () => {
      const res = await getUsers(jwt);
      console.log(res);
      if (res.status === "success") {
        setUsers(res.data.users);
        setIsLoading(false);
      }
    };
    fetchUsers();
  }, [jwt, reload]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <section className={classes.users}>
      <h2>List of all users</h2>
      <div className={classes.list}>
        {users.map((el) => (
          <UserItem
            key={el._id}
            id={el._id}
            name={el.name}
            email={el.email}
            onReload={setReload}
            country={el.country}
          />
        ))}
      </div>
    </section>
  );
};

export default Users;
