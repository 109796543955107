import { Fragment } from "react";
import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
} from "react-router-dom";

import Layout from "./components/layout/Layout";
import ErrorModal from "./components/UI/ErrorModal";
import Home from "./components/main/Home";
import Dashboard from "./components/layout/Dashboard";
import Login from "./components/auth/Login";
import VerifyLogin from "./components/auth/VerifyLogin";
import Users from "./components/dashboard/Users";
import Investments from "./components/dashboard/Investments";
import Wallet from "./components/dashboard/Wallet";
import Password from "./components/dashboard/Password";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<Layout />} errorElement={<ErrorModal />}>
      <Route index element={<Home />} />
      <Route path="login" element={<Login />} />
      <Route path="verify-login" element={<VerifyLogin />} />
      <Route path="dashboard" element={<Dashboard />}>
        <Route index element={<Users />} />
        <Route path="users" element={<Users />} />
        <Route path="investments" element={<Investments />} />
        <Route path="wallet" element={<Wallet />} />
        <Route path="password" element={<Password />} />
      </Route>
    </Route>
  )
);

function App() {
  return (
    <Fragment>
      <RouterProvider router={router} />
    </Fragment>
  );
}

export default App;
