import { Outlet } from "react-router-dom";

import Header from "../main/Header";
import Footer from "../main/Footer";

const Layout = () => {
  return (
    <div className="layout">
      <Header />
      <main>
        <Outlet />
      </main>
      <Footer />
    </div>
  );
};

export default Layout;
